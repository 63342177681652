(function(){
	'use strict';

	angular.module('3wks.selectAll', [])
		.directive('selectAll', [function () {
			return {
				restrict: 'E',
				scope: {
					items: "=",
					property: "@"
				},
				template: '<input type="checkbox" ng-model="selectAll" />',

				controller: function ($scope) {
					$scope.$watch("selectAll", function() {
						var selectAll = !!$scope.selectAll;
						angular.forEach($scope.items, function(item) {
							item[$scope.property] = selectAll;
						});
					});
				}
			};
		}]);
})();